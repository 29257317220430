<template>
  <section>
    <h6 class="mt-2">{{ $t('hotspot.domainList') }}</h6>

    <div class="form-group mb-h">
      <label for="domain">{{ $t('hotspot.domain') }}</label>
      <div class="input-group">
        <input
          type="text"
          name="domain"
          class="form-control"
          placeholder="www.example.com"
          id="domain"
          :disabled="isDisable"
          @keyup.13="addDomain"
          v-validate="'required'"
          data-vv-validate-on="input"
          v-model="newDomain"
          :class="{ input: true, 'is-danger': errors.has('domain') }"
        />
        <span class="input-group-btn">
          <button
            class="btn btn-success mb-0"
            type="button"
            @click="addDomain"
            :disabled="!newDomain || errors.has('mac') || isDisable"
          >
            {{ $t('general.add') }}
          </button>
        </span>
      </div>
    </div>
    <div class="items_list mb-1 mt-h">
      <ul class="list-unstyled" v-if="hotspotData.domains && hotspotData.domains.length">
        <li
          v-for="(domain, index) in hotspotData.domains"
          @click="removeDomain"
          :data-index="index"
          class="item-for-delete ml-h mb-q"
          :class="{ 'item-for-delete--disable': isDisable }"
        >
          {{ domain }}
          <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
        </li>
      </ul>
      <small class="text-muted" v-else>{{ $t('hotspot.noItems') }}</small>
    </div>

    <h6 class="mt-2">{{ $t('hotspot.roamingConsortiumList') }}</h6>

    <div class="row url-input-double">
      <div class="form-group mr-h mb-0">
        <label for="consortium_name">{{ $t('general.name') }}</label>
        <input
          type="text"
          name="consortium_name"
          class="form-control"
          :placeholder="$t('general.name')"
          id="consortium_name"
          v-validate="'required'"
          :disabled="isDisable"
          data-vv-validate-on="input"
          @keyup.13="addConsortium"
          v-model="newConsortium.name"
          :class="{ input: true, 'is-danger': errors.has('consortium_name') }"
        />
      </div>
      <div class="form-group mr-h mb-0">
        <label for="consortium_id">{{$t('hotspot.organizationID')}}</label>
        <input
          type="text"
          name="consortium_id"
          class="form-control"
          :placeholder="$t('hotspot.organizationID')"
          id="consortium_id"
          v-validate="'required|hexhs'"
          :disabled="isDisable"
          data-vv-validate-on="input"
          @keyup.13="addConsortium"
          v-model="newConsortium.id"
          :class="{ input: true, 'is-danger': errors.has('consortium_id') }"
        />
      </div>

      <div class="">
        <button
          class="btn btn-success mb-0"
          type="button"
          @click="addConsortium"
          :disabled="!newConsortium.name || !newConsortium.id || errors.any() || isDisable"
        >
          {{ $t('general.add') }}
        </button>
      </div>
    </div>
    <div class="items_list mb-1 mt-h">
      <ul class="list-unstyled" v-if="hotspotData.consortiums && hotspotData.consortiums.length">
        <li
          v-for="(name, index) in hotspotData.consortiums"
          @click="removeConsortium"
          :data-index="index"
          :class="{ 'item-for-delete--disable': isDisable }"
          class="item-for-delete ml-h mb-q"
        >
          {{ name.name }}&nbsp; - &nbsp;{{ name.id }}
          <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
        </li>
      </ul>
      <small class="text-muted" v-else>{{ $t('hotspot.noItems') }}</small>
    </div>

    <h6 class="mt-2">{{ $t('hotspot.threeGPP') }}</h6>

    <div class="row url-input-double">
      <div class="form-group mr-h mb-0">
        <label for="cellular_name">{{ $t('general.name') }}</label>
        <input
          type="text"
          name="cellular_name"
          class="form-control"
          :placeholder="$t('general.name')"
          id="cellular_name"
          v-validate="'required'"
          data-vv-validate-on="input"
          :disabled="isDisable"
          @keyup.13="addCellular"
          v-model="newCellular.name"
          :class="{ input: true, 'is-danger': errors.has('cellular_name') }"
        />
      </div>
      <div class="form-group mr-h mb-0">
        <label for="cellular_mcc">MCC</label>
        <input
          type="text"
          name="cellular_mcc"
          class="form-control"
          placeholder="MCC"
          id="cellular_mcc"
          v-validate="'required|numeric'"
          data-vv-validate-on="input"
          :disabled="isDisable"
          @keyup.13="addCellular"
          v-model.number="newCellular.mcc"
          :class="{ input: true, 'is-danger': errors.has('cellular_mcc') }"
        />
      </div>

      <div class="form-group mr-h mb-0">
        <label for="cellular_mnc">MNC</label>
        <input
          type="text"
          name="cellular_mnc"
          class="form-control"
          placeholder="MNC"
          id="cellular_mnc"
          v-validate="'required|numeric'"
          data-vv-validate-on="input"
          :disabled="isDisable"
          @keyup.13="addCellular"
          v-model.number="newCellular.mnc"
          :class="{ input: true, 'is-danger': errors.has('cellular_mnc') }"
        />
      </div>
      <div class="">
        <button
          class="btn btn-success mb-0"
          type="button"
          @click="addCellular"
          :disabled="!newCellular.name || !newCellular.mcc || !newCellular.mnc || errors.any() || isDisable"
        >
          {{ $t('general.add') }}
        </button>
      </div>
    </div>
    <div class="items_list mb-1 mt-h">
      <ul class="list-unstyled" v-if="hotspotData.cellular && hotspotData.cellular.length">
        <li
          v-for="(name, index) in hotspotData.cellular"
          @click="removeCellular"
          :data-index="index"
          :class="{ 'item-for-delete--disable': isDisable }"
          class="item-for-delete ml-h mb-q"
        >
          {{ name.name }} - {{ name.mcc }} - {{ name.mnc }}
          <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
        </li>
      </ul>
      <small class="text-muted" v-else>{{ $t('hotspot.noItems') }}</small>
    </div>

    <h6 class="mt-2">{{ $t('hotspot.connectionCapabilityList') }}</h6>

    <div class="row url-input-double">
      <div class="form-group mb-0 mr-h">
        <label for="conn_cap_protocol">{{ $t('hotspot.protocol') }}</label>
        <select
          v-model="newConnCap.protocol"
          :class="{ 'select-disabled': isDisable }"
          :disabled="isDisable"
          name="conn_cap_protocol"
          id="conn_cap_protocol"
          class="form-control pr-1"
        >
          <option v-for="(type, key) in hotspotService.IP_PROTOCOLS" :value="+key">{{ key }} - {{ type.name }}</option>
        </select>
      </div>

      <div class="form-group mr-h mb-0 conn_cap_port">
        <label for="conn_cap_port">{{ $t('hotspot.port') }}</label>
        <input
          type="text"
          name="conn_cap_port"
          class="form-control"
          placeholder="Port"
          id="conn_cap_port"
          v-validate="'required|numeric|between:0,65535'"
          data-vv-validate-on="input"
          :disabled="isDisable"
          @keyup.13="addConnCap"
          v-model.number="newConnCap.port"
          :class="{ input: true, 'is-danger': errors.has('conn_cap_port') }"
        />
      </div>

      <div class="form-group mb-0 mr-h">
        <label for="conn_cap_status">{{ $t('hotspot.protocol') }}</label>
        <select
          v-model="newConnCap.status"
          :class="{ 'select-disabled': isDisable }"
          :disabled="isDisable"
          name="conn_cap_status"
          id="conn_cap_status"
          class="form-control pr-1"
        >
          <option v-for="(type, key) in hotspotService.CONN_CAP_STATUS" :value="+key">
            {{ key }} - {{ type.name }}
          </option>
        </select>
      </div>

      <div class="">
        <button class="btn btn-success mb-0" type="button" @click="addConnCap" :disabled="errors.any() || isDisable">
          {{ $t('general.add') }}
        </button>
      </div>
    </div>
    <div class="items_list mb-1 mt-h">
      <ul class="list-unstyled" v-if="hotspotData.conn_caps && hotspotData.conn_caps.length">
        <li
          v-for="(item, index) in hotspotData.conn_caps"
          @click="removeConnCap"
          :data-index="index"
          :class="{ 'item-for-delete--disable': isDisable }"
          class="item-for-delete ml-h mb-q"
        >
          {{ item.protocol }} : {{ item.port }} : {{ item.status }}
          <i class="fa fa-remove text-danger" v-if="!isDisable"></i>
        </li>
      </ul>
      <small class="text-muted" v-else>{{ $t('hotspot.noItems') }}</small>
    </div>

    <h6 class="mt-2">{{ $t('hotspot.WANMetrics') }}</h6>

    <div class="form-group mb-h mr-h">
      <label for="wan_info">Link Status</label>
      <select
        v-model="hotspotData.wan_metrics.wan_info"
        :class="{ 'select-disabled': isDisable }"
        :disabled="isDisable"
        name="wan_info"
        id="wan_info"
        class="form-control pr-1"
      >
        <option v-for="(type, key) in hotspotService.LINK_STATUS" :value="+key">{{ key }} - {{ type.name }}</option>
      </select>
    </div>

    <div class="form-group mr-h mb-h">
      <label for="downlink_speed">Downlink Speed (in kbps)</label>
      <input
        type="text"
        name="downlink_speed"
        class="form-control"
        placeholder="Downlink Speed"
        id="downlink_speed"
        :disabled="isDisable || hotspotData.wan_metrics.wan_info === 0"
        v-validate="'required|numeric|between:0,4294967295'"
        data-vv-validate-on="input"
        v-model.number="hotspotData.wan_metrics.dl_speed"
        :class="{ input: true, 'is-danger': errors.has('downlink_speed') }"
      />
    </div>

    <div class="form-group mr-h mb-h">
      <label for="uplink_speed">Uplink Speed (in kbps)</label>
      <input
        type="text"
        name="uplink_speed"
        class="form-control"
        placeholder="Uplink Speed"
        id="uplink_speed"
        :disabled="isDisable || hotspotData.wan_metrics.wan_info === 0"
        v-validate="'required|numeric|between:0,4294967295'"
        data-vv-validate-on="input"
        v-model.number="hotspotData.wan_metrics.ul_speed"
        :class="{ input: true, 'is-danger': errors.has('uplink_speed') }"
      />
    </div>

    <div class="form-group mr-h mb-h">
      <label for="downlink_load">Downlink Load</label>
      <input
        type="text"
        name="downlink_load"
        class="form-control"
        placeholder="Downlink Load"
        id="downlink_load"
        :disabled="isDisable || hotspotData.wan_metrics.wan_info === 0"
        v-validate="'required|numeric|between:0,255'"
        data-vv-validate-on="input"
        v-model.number="hotspotData.wan_metrics.dl_load"
        :class="{ input: true, 'is-danger': errors.has('downlink_load') }"
      />
    </div>

    <div class="form-group mr-h mb-h">
      <label for="uplink_load">Uplink Load</label>
      <input
        type="text"
        name="uplink_load"
        class="form-control"
        placeholder="Uplink Load"
        id="uplink_load"
        :disabled="isDisable || hotspotData.wan_metrics.wan_info === 0"
        v-validate="'required|numeric|between:0,255'"
        data-vv-validate-on="input"
        v-model.number="hotspotData.wan_metrics.ul_load"
        :class="{ input: true, 'is-danger': errors.has('uplink_load') }"
      />
    </div>

    <div class="form-group mr-h mb-h">
      <label for="wan_lmd">Load Measurement Duration</label>
      <input
        type="text"
        name="wan_lmd"
        class="form-control"
        placeholder="Load Measurement Duration"
        id="wan_lmd"
        :disabled="isDisable || hotspotData.wan_metrics.wan_info === 0"
        v-validate="'required|numeric|between:0,65535'"
        data-vv-validate-on="input"
        v-model.number="hotspotData.wan_metrics.lmd"
        :class="{ input: true, 'is-danger': errors.has('wan_lmd') }"
      />
    </div>

    <h6 class="mt-2">{{ $t('hotspot.operatingClassIndication') }}</h6>

    <div class="row url-input-double">
      <div class="form-group mr-h mb-0">
        <label for="new_oper_class">Operating Class</label>
        <select
          v-model="newOperClass"
          name="new_oper_class"
          id="new_oper_class"
          class="form-control"
          :class="{ 'select-disabled': isDisable }"
          :disabled="isDisable"
        >
          <option value="" disabled selected>{{$t('hotspot.selectClass')}}</option>
          <option
            v-for="(operClass, key) in hotspotService.OPER_CLASSES"
            :disabled="hotspotData.oper_classes && hotspotData.oper_classes.includes(+key)"
            :value="+key"
          >
            {{ key }}: {{ operClass.name }}
          </option>
        </select>
      </div>
      <div class="">
        <button
          class="btn btn-success mb-0"
          type="button"
          @click="addOperClass"
          :disabled="errors.any() || !newOperClass"
        >
          {{ $t('general.add') }}
        </button>
      </div>
    </div>
    <div class="items_list mb-1 mt-h">
      <ul class="list-unstyled" v-if="hotspotData.oper_classes && hotspotData.oper_classes.length">
        <li
          v-for="(type, index) in hotspotData.oper_classes"
          @click="removeOperClass"
          :data-index="index"
          :class="{ 'item-for-delete--disable': isDisable }"
          class="item-for-delete ml-h mb-q"
        >
          {{ type }}: {{ hotspotService.OPER_CLASSES[type].name }}
          <i class="fa fa-remove text-danger" v-if="!isDisable"></i>
        </li>
      </ul>
      <small class="text-muted" v-else>{{ $t('hotspot.noItems') }}</small>
    </div>

    <h6 class="mt-2">{{ $t('hotspot.operatorFriendlyNameList') }}</h6>
    <div class="row url-input-double">
      <div class="form-group mr-h mb-0">
        <label for="operator_name">{{ $t('general.name') }}</label>
        <input
          type="text"
          name="operator_name"
          class="form-control"
          :placeholder="$t('general.name')"
          id="operator_name"
          :disabled="isDisable"
          v-validate="'required'"
          data-vv-validate-on="input"
          @keyup.13="addOperatorName"
          v-model="newOperatorName.name"
          :class="{ input: true, 'is-danger': errors.has('venue_name') }"
        />
      </div>
      <div class="form-group mr-h mb-0">
        <label for="operator_language">{{ $t('hotspot.language') }}</label>
        <select
          v-model="newOperatorName.lang"
          name="operator_language"
          id="operator_language"
          class="form-control"
          :class="{ 'select-disabled': isDisable }"
          :disabled="isDisable"
        >
<!--          <option v-for="lang in hotspotService.LANGUAGE" :value="lang.id">{{ lang.name }}</option>-->
          <option v-for="lang in sortedByNameLangList" :value="lang.id">{{ lang.name }}</option>
        </select>
      </div>
      <div class="">
        <button
          class="btn btn-success mb-0"
          type="button"
          @click="addOperatorName"
          :disabled="!newOperatorName.name || !newOperatorName.lang || errors.any()"
        >
          {{ $t('general.add') }}
        </button>
      </div>
    </div>
    <div class="items_list mb-1 mt-h">
      <ul class="list-unstyled" v-if="hotspotData.oper_names && hotspotData.oper_names.length">
        <li
          v-for="(name, index) in hotspotData.oper_names"
          @click="removeOperatorName"
          :data-index="index"
          :class="{ 'item-for-delete--disable': isDisable }"
          class="item-for-delete ml-h mb-q"
        >
<!--          {{ name.name }}&nbsp;( {{ name.lang }} )-->
          {{ name.name }}&nbsp;( {{ getLangNameWithI18nById(name.lang) }} )
          <i class="fa fa-remove text-danger" v-if="!isDisable"></i>
        </li>
      </ul>
      <small class="text-muted" v-else>{{ $t('hotspot.noItems') }}</small>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AdvancedList',
  inject: ['$validator'],
  props: {
    hotspotData: {
      type: Object
    },
    hotspotService: {
      type: Object
    },
    commonService: {
      type: Object
    },
    isDisable: {
      type: Boolean
    }
  },
  data() {
    return {
      newDomain: '',
      newOperClass: '',
      newConsortium: {
        name: '',
        id: ''
      },
      oldLinkStatus: 0,
      oper_classes: [],
      newCellular: {
        name: '',
        mcc: 1,
        mnc: 1
      },
      newOperatorName: {
        lang: 'eng',
        name: ''
      },
      newConnCap: {
        protocol: 1,
        port: 0,
        status: 0
      }
    };
  },
  computed: {
    sortedByNameLangList() {
      const unsortedLangs = JSON.parse(JSON.stringify(this.hotspotService.LANGUAGE))
      const compare = function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      const sortedLangs = unsortedLangs.sort(compare);
      return sortedLangs
    },
    linkStatus() {
      return this.hotspotData.wan_metrics.wan_info;
    }
  },
  watch: {
    linkStatus(newVal, oldVal) {
      // if (!newVal) {
      //     this.hotspotData.wan_metrics.dl_speed = '';
      //     this.hotspotData.wan_metrics.dl_load = '';
      //     this.hotspotData.wan_metrics.ul_speed = '';
      //     this.hotspotData.wan_metrics.ul_load = '';
      //     this.hotspotData.wan_metrics.lmd = '';
      // } else if (!oldVal) {
      //     this.hotspotData.wan_metrics.dl_speed = 0;
      //     this.hotspotData.wan_metrics.dl_load = 0;
      //     this.hotspotData.wan_metrics.ul_speed = 0;
      //     this.hotspotData.wan_metrics.ul_load = 0;
      //     this.hotspotData.wan_metrics.lmd = 0;
      // }
    }
  },
  methods: {
    getLangNameWithI18nById(langId) {
      try {
        const langsList = JSON.parse(JSON.stringify(this.hotspotService.LANGUAGE))
        const langObj = langsList.find((lang) => {
          return lang.id === langId;
        })
        if (Object.keys(langObj).length && langObj.name) {
          return langObj.name;
        }
        return langId
      } catch (e) {
        console.log(e)
        return langId
      }
    },
    addDomain() {
      this.$validator.validateAll({ domain: this.newDomain }).then((result) => {
        if (result) {
          this.hotspotData.domains.push(this.newDomain);
          this.newDomain = '';
          this.$validator.reset();
        }
      });
    },
    removeDomain(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.hotspotData.domains.splice(index, 1);
    },
    addConsortium() {
      this.$validator
        .validateAll({ consortium_name: this.newConsortium.name, consortium_id: this.newConsortium.id })
        .then((result) => {
          if (result) {
            this.hotspotData.consortiums.push({
              id: this.newConsortium.id,
              name: this.newConsortium.name
            });
            this.newConsortium.id = '';
            this.newConsortium.name = '';
            this.$validator.reset();
          }
        });
    },
    removeConsortium(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.hotspotData.consortiums.splice(index, 1);
    },
    removeCellular(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.hotspotData.cellular.splice(index, 1);
    },

    addCellular() {
      this.$validator
        .validateAll({
          cellular_name: this.newCellular.name,
          cellular_mcc: this.newCellular.mcc,
          cellular_mnc: this.newCellular.mnc
        })
        .then((result) => {
          if (result) {
            this.hotspotData.cellular.push({
              name: this.newCellular.name,
              mcc: this.newCellular.mcc,
              mnc: this.newCellular.mnc
            });
            this.newCellular.name = '';
            this.newCellular.mcc = 1;
            this.newCellular.mnc = 1;
            this.$validator.reset();
          }
        });
    },

    addOperatorName() {
      this.$validator
        .validateAll({ venue_name: this.newOperatorName.name, venue_language: this.newOperatorName.lang })
        .then((result) => {
          if (result) {
            this.hotspotData.oper_names.push({
              lang: this.newOperatorName.lang,
              name: this.newOperatorName.name
            });
            this.newOperatorName.lang = 'eng';
            this.newOperatorName.name = '';
            this.$validator.reset();
          }
        });
    },
    removeOperatorName(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.hotspotData.oper_names.splice(index, 1);
    },

    addConnCap() {
      this.$validator
        .validateAll({
          conn_cap_port: this.newConnCap.port
        })
        .then((result) => {
          if (result) {
            this.hotspotData.conn_caps.push({
              protocol: this.newConnCap.protocol,
              port: this.newConnCap.port,
              status: this.newConnCap.status
            });
            this.newConnCap.protocol = 1;
            this.newConnCap.port = 0;
            this.newConnCap.status = 0;
            this.$validator.reset();
          }
        });
    },

    addOperClass() {
      this.$validator
        .validateAll({
          new_oper_class: this.newOperClass
        })
        .then((result) => {
          if (result) {
            if (!this.hotspotData.oper_classes.includes(this.newOperClass)) {
              this.hotspotData.oper_classes.push(this.newOperClass);
              this.newOperClass = '';
              this.$validator.reset();
            } else {
              alert('wwww');
            }
          }
        });
    },

    removeOperClass(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.hotspotData.oper_classes.splice(index, 1);
    },

    removeConnCap(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.hotspotData.conn_caps.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.conn_cap_port {
  max-width: 70px;
}
</style>
