<template>
  <section>
    <div class="h6 mt-2 mb-1">
      {{ $t('hotspot.naiRealmList') }}
      <button class="btn btn-success btn-sm pull-right" @click="openModal" v-if="!isDisable">
        {{ $t('hotspot.addRealm') }}
      </button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>{{ $t('general.name') }}</th>
          <th>{{ $t('hotspot.eapMethod') }}</th>
          <th>{{ $t('hotspot.authType') }}</th>
          <th v-if="!isDisable"></th>
        </tr>
      </thead>

      <tbody v-if="hotspotData.realms.length">
        <tr v-for="(realm, index) in hotspotData.realms" class="realm-row" :data-index="index" @click="editRealm">
          <td class="responsive-row">{{ realm.name }}</td>
          <td>{{ hotspotService.EAP_METHODS[realm.eap].name }}</td>
          <td>
            <span class="badge badge-light mr-q badge-success">{{ realm.auth.length }}</span>
          </td>
          <td v-if="!isDisable" class="action-delete" data-action="delete" @click.stop="removeRealm(index)">
            <i class="fa fa-remove text-danger ml-h"></i>
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td colspan="3" class="text-center text-muted">{{ $t('hotspot.realmNoData') }}</td>
        </tr>
      </tbody>
    </table>

    <modal title="Add realm" v-model="isModalShow" class="modal-success" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">Realm</h4>

      <div class="row">
        <div class="col-lg-12">
          <div class="form-group mt-h">
            <label for="name">{{ $t('general.name') }}</label>
            <input
              type="text"
              name="name"
              id="name"
              :disabled="isDisable"
              :placeholder="$t('hotspot.enterRealmNamePlaceholder')"
              class="form-control"
              v-validate="'required'"
              :class="{ input: true, 'is-danger': errors.has('name') }"
              v-model="currentRealm.name"
            />
            <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
          </div>

          <div class="form-group">
            <label for="eap">{{ $t('hotspot.eapMethod') }}</label>
            <select
              v-model="currentRealm.eap"
              name="eap"
              id="eap"
              class="form-control"
              :class="{ 'select-disabled': isDisable }"
              :disabled="isDisable"
            >
              <option v-for="(method, key) in hotspotService.EAP_METHODS" :value="+key">{{ method.name }}</option>
            </select>
          </div>

          <div class="row url-input-double">
            <div class="form-group mr-h mb-0">
              <label for="auth_type">{{ $t('hotspot.authType') }}</label>
              <select
                v-model="currentAuth.type"
                name="auth_type"
                id="auth_type"
                class="form-control"
                :class="{ 'select-disabled': isDisable }"
                :disabled="isDisable"
                @change="checkAuthType"
              >
                <option v-for="(type, key) in hotspotService.EAP_TYPE" :value="+key">{{ type.name }}</option>
              </select>
            </div>
            <div class="form-group mr-h mb-0">
              <label for="auth_subtype">{{ $t('hotspot.authSubType') }}</label>
              <select
                v-model="currentAuth.subtype"
                name="auth_subtype"
                id="auth_subtype"
                class="form-control"
                :class="{ 'select-disabled': isDisable }"
                :disabled="isDisable"
              >
                <option
                  v-if="hotspotService.EAP_TYPE[currentAuth.type]"
                  v-for="(type, key) in hotspotService.EAP_TYPE[currentAuth.type].subtype"
                  :value="+key"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
            <div class="">
              <button class="btn btn-success mb-0" type="button" @click="addAuth" :disabled="isDisable">
                {{ $t('general.add') }}
              </button>
            </div>
          </div>
          <div class="items_list mb-1 mt-h">
            <ul class="list-unstyled" v-if="currentRealm.auth.length">
              <li
                v-for="(auth, index) in currentRealm.auth"
                @click="removeAuth"
                :data-index="index"
                class="item-for-delete ml-h mb-q"
                :class="{ 'item-for-delete--disable': isDisable }"
              >
                {{ hotspotService.EAP_TYPE[auth.type].name }}&nbsp; - &nbsp;{{
                  hotspotService.EAP_TYPE[auth.type].subtype[auth.subtype].name
                }}
                <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
              </li>
            </ul>
            <small class="text-muted ml-h" v-else>{{ $t('hotspot.realmNoAuth') }}</small>
          </div>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <button
          v-if="!isEditRealm && !editRealmIndex"
          type="button"
          class="btn btn-success"
          @click="addRealm"
          :disabled="!currentRealm.name || !currentRealm.auth.length"
        >
          {{ $t('general.add') }}
        </button>
        <button
          v-else
          type="button"
          class="btn btn-success"
          @click="saveRealm"
          :disabled="!currentRealm.name || !currentRealm.auth.length || isDisable"
        >
          {{ $t('general.save') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="resetRealm">{{ $t('general.close') }}</button>
      </div>
    </modal>
  </section>
</template>

<script>
import Modal from '../Modal.vue';

export default {
  name: 'Realm',
  components: { Modal },
  inject: ['$validator'],
  props: {
    hotspotData: {
      type: Object
    },
    hotspotService: {
      type: Object
    },
    commonService: {
      type: Object
    },
    isDisable: {
      type: Boolean
    }
  },
  data() {
    return {
      currentRealm: {
        name: '',
        eap: 13,
        auth: []
      },
      currentAuth: {
        type: 2,
        subtype: 1
      },
      editRealmIndex: null,
      isModalShow: false,
      isEditRealm: false
    };
  },
  methods: {
    openModal() {
      this.resetRealm();
      this.isModalShow = true;
    },
    addAuth() {
      this.$validator
        .validateAll({ auth_type: this.currentAuth.type, auth_subtype: this.currentAuth.subtype })
        .then((result) => {
          if (result) {
            this.currentRealm.auth.push({
              type: this.currentAuth.type,
              subtype: this.currentAuth.subtype
            });
            this.currentAuth.type = 2;
            this.currentAuth.subtype = 1;
            this.$validator.reset();
          }
        });
    },
    removeAuth(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.currentRealm.auth.splice(index, 1);
    },
    checkAuthType() {
      if (this.currentAuth.type === 2) {
        this.currentAuth.subtype = 1;
      } else if (this.currentAuth.type === 3) {
        this.currentAuth.subtype = 0;
      } else if (this.currentAuth.type === 5) {
        this.currentAuth.subtype = 1;
      }
    },

    resetRealm() {
      this.isModalShow = false;
      this.currentRealm = {
        name: '',
        eap: 13,
        auth: []
      };
      this.isEditRealm = false;
      this.editRealmIndex = null;
      this.$validator.reset();
    },

    addRealm() {
      this.$validator.validateAll({ name: this.currentRealm.name }).then((result) => {
        if (result) {
          this.hotspotData.realms.push(this.currentRealm);
          this.$validator.reset();
          this.resetRealm();
        }
      });
    },

    saveRealm() {
      this.$validator.validateAll({ name: this.currentRealm.name }).then((result) => {
        if (result) {
          this.hotspotData.realms[this.editRealmIndex] = this.currentRealm;
          this.$validator.reset();
          this.resetRealm();
        }
      });
    },

    editRealm(e) {
      if (e.target.dataset.action === 'delete') {
        return;
      }

      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;

      if (!this.hotspotData.realms[index]) {
        return;
      }

      this.isEditRealm = true;
      this.editRealmIndex = index;
      this.currentRealm = this.hotspotData.realms[index];
      this.isModalShow = true;
    },
    removeRealm(index) {
      if (index === undefined) {
        return;
      }
      this.hotspotData.realms.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.realm-row {
  transition: background 0.15s;
  cursor: pointer;
}

.realm-row.selected {
  background: #d9e3ec;
}

.realm-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.action-delete .fa-remove {
  transition: all 0.3s;
  opacity: 0.6;
}

.action-delete:hover .fa-remove {
  opacity: 1;
}
</style>
